@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";

.select-wrap {
	padding: $space-10 $space-0;
}

.list-select {
	background-color: $color-white;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	border: 1px solid $color-border;

	&__label {
		position: relative;
		display: flex;
		justify-content: space-between;
		padding: $space-10;
		font-size: $f-size-m;
		font-family: $f-main-l;
		cursor: pointer;
		color: $color-border-dark;
	}

	&__categoty-type {
		font-size: $f-size-m;
		font-family: $f-main-l;
		cursor: pointer;
		color: $color-border-dark;
		font-size: 14px;
		display: inline-block;
		margin-left: auto;
		margin-right: $space-10;
	}

	&__drop {
		width: 20px;

		&--active {
			transform: rotate(180deg);
		}
	}

	&__list {
		padding: $space-0;
		margin: $space-0;
		max-height: 200px;
		overflow-y: auto;
	}

	&__item {
		list-style: none;
		padding: $space-10;
		font-size: $f-size-m;
		font-family: $f-main;
		cursor: pointer;
		transition: 0.3s;
		color: $color-text;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:hover {
			background-color: $color-border;
			transition: 0.3s;

			.list-select__checkbox {
				border: 1px solid $color-border-dark;
				transition: 0.3s;
			}
		}
	}

	&__checkbox {
		width: 20px;
		height: 20px;
		border-radius: 3px;
		border: 1px solid $color-border;
		display: block;
		transition: 0.3s;
		background-color: transparent;

		&--active {
			background-color: $color-green;
		}
	}
}

.search-wrapper {
	padding: $space-10;
}

.search-item {
	width: 100%;
	border: 1px solid $color-border;
	padding: $space-10;
	border-radius: 5px;
}
