@import '../settings/breakpoints.scss';
@import '../settings/variable.scss';

.textarea{
    padding: 5px 0px;

    &__label{
          font-size: 18px;
          font-family: $f-main-l;
          font-weight: 500;
          letter-spacing: 1px;
          color: $color-text;
          display: flex;
          margin-bottom: 10px;
    }
    &__text{
        width: 100%;
         padding: $space-5 $space-10;
        border: 1px solid $color-border;
        box-shadow: $shadow;
        border-radius: 5px;
        height: 52px;
        font-size: 18px;
        font-family: $f-main;
        color: $color-text;
        height: 200px;
        resize: none;
    }
}