@import "./variable.scss";

@mixin select {
	border: 1px solid $color-border;
	padding: 10px 20px;
	box-shadow: $shadow;
	border-radius: 5px;
	background-color: $color-white;
	z-index: 3;
}

@mixin button($type: "inherit") {
	padding: 10px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	//font-family: $f-main;
	font-size: 16px;
	transform: scale(1);
	transition: 0.3s;
	border-right: 0.5px solid transparent;
	border-bottom: 0.5px solid transparent;
	background-color: inherit;
	color: $color-text;

	@if ($type== "success") {
		background-color: $color-blue;
		color: $color-white;
	}

	@if ($type== "dark") {
		background-color: $color-border-dark;
		color: $color-white;
	}

	@if ($type== "light") {
		background-color: $color-border;
		color: $color-text;
	}

	&:hover {
		opacity: 0.8;
	}
}

@mixin input($type: "text") {
	position: relative;
	min-width: 100px;
	flex-grow: 1;
	padding: 5px 0px;

	&__label {
		font-size: 18px;
		font-family: $f-main-l;
		font-weight: 500;
		letter-spacing: 1px;
		color: $color-text;
		display: flex;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
	}

	&__input {
		width: 100%;
		padding: $space-5 $space-10;
		border: 1px solid $color-border;
		box-shadow: $shadow;
		border-radius: 5px;
		height: 52px;
		font-size: 24px;
		font-family: $f-main;
		color: $color-text;

		&::placeholder {
			color: $color-border-dark;
			font-weight: 300;
			font-family: $f-main-l;
		}
	}
}

@mixin container {
	position: relative;
	width: 100%;
	padding: $space-15;
}
