@import "../settings/mixin.scss";

.button {
	@include button("inherit");

	max-width: 200px;
	width: 100%;
	&:active,
	&:focus {
		outline: none;
	}
	&:disabled {
		opacity: 0.5;
	}
}

.success-button {
	@include button("success");
}
.dark-button {
	@include button("dark");
}
.light-button {
	@include button("light");
}
