@import "../../styles/settings/variable.scss";

.color-picker {
  position: relative;

  &__inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 450px;
  }

  &__output {
    width: 220px;
    height: 168px;
    border-radius: 5px;
    display: block;
    border: 2px solid $color-border;
    cursor: pointer;
  }

  &__input {
    width: 100px;
    height: 50px;
    border-radius: 5px;
    display: block;
    border: 2px solid $color-border;
    margin-left: $space-5;
    padding: $space-10;
    font-size: $f-size;
  }

  .block-picker {
    z-index: 1;
    padding-bottom: $space-15;
  }

  &__close {
    position: relative;
    width: $space-15;
    height: $space-15;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-text;
    bottom: -5px;
    left: -5px;
    z-index: 2;
    background-color: $color-white;
    border-radius: 50%;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 2px;
      background-color: $color-text;
      transform: rotate(45deg);
    }

    &::after {
      content: "";
      position: absolute;
      width: 7px;
      height: 2px;
      background-color: $color-text;
      transform: rotate(135deg);
    }
  }
}

.material-picker {
  width: 220px !important;
  height: 168px !important;
}
