@font-face{
    font-family: avertademo;
    src:url(../../assets/fonts/Avertademo.ttf)
};
@font-face{
    font-family: futuraLight;
    src: url(../../assets/fonts/FuturaLight.ttf);
};
@font-face{
    font-family: futuraMedium;
    src: url(../../assets/fonts/FuturaMedium.ttf);
}