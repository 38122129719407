@import "../../styles/settings/variable.scss";
@import "../../styles/settings/mixin.scss";

.custom-select {
	min-width: 100px;
	flex-grow: 1;
	padding: 5px 0px;

	&__label {
		font-size: 18px;
		font-family: $f-main;
		font-weight: 500;
		letter-spacing: 1px;
		color: $color-text;
		display: flex;
		margin-bottom: $space-10;
	}

	&__type {
		font-size: 12px;
		font-family: $f-main;
		font-weight: 500;
		letter-spacing: 1px;
		color: $color-text;
		display: flex;
	}

	&__select {
		@include select;
	}

	&__list {
		display: none;
		padding-left: 0;
		top: 25px;
		right: 0;
		width: 100%;
	}
	&__title,
	&__option {
		list-style: none;
		font-size: 18px;
		font-family: $f-main;
		color: $color-text;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 50px 0px 0px;
		transition: 0.3s;

		&:hover {
			background-color: $hover-grey-light;
			padding-left: 10px;
			transition: 0.3s;
		}
	}

	&__option {
		align-items: flex-end;
		border-bottom: 0.5px solid $color-border;
	}

	&__first-option {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 50px 0px 0px;
		font-size: 18px;
		font-family: $f-main;
		font-weight: 500;
		cursor: pointer;

		&::before {
			position: absolute;
			content: "";
			display: block;
			width: 15px;
			height: 1px;
			right: 15px;
			background-color: $color-text;
			transform: rotate(45deg);
		}

		&::after {
			position: absolute;
			content: "";
			display: block;
			width: 15px;
			height: 1px;
			right: 5px;
			background-color: $color-text;
			transform: rotate(135deg);
		}
	}
}

.custom-select--collapse {
	.custom-select__list {
		display: flex;
		flex-direction: column;
	}

	.custom-select__first-option {
		&::before {
			position: absolute;
			content: "";
			display: block;
			width: 15px;
			height: 1px;
			right: 15px;
			background-color: $color-text;
			transform: rotate(135deg);
		}

		&::after {
			position: absolute;
			content: "";
			display: block;
			width: 15px;
			height: 1px;
			right: 5px;
			background-color: $color-text;
			transform: rotate(45deg);
		}
	}
}
