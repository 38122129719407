@import "../../../styles/settings/variable.scss";
@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/mixin.scss";

.courses {
	padding: $space-0 $space-15;
	max-width: 993px;
	width: 100%;

	&__header {
		width: 100%;
		display: flex;
		align-items: center;
		padding: $space-10 $space-0;
	}

	&__title-day {
		font-size: 24px;
		color: $color-title;
		margin: 0;
	}
}
.drag {
	display: flex;
	flex-direction: column;
	max-width: 768px;
	margin-top: $space-50;
}
.item {
	width: 100%;
	height: 50px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding: $space-0 $space-10;
	border: 1px solid $color-border-dark;
	margin: 5px 0px;

	&__image {
		width: 80px;
		height: call(80 / 16 * 9);
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		margin-right: 10px;
	}

	&__title {
		flex-grow: 1;
	}

	&__order {
		width: 30px;
	}

	&__time {
		margin-right: $space-10;
		width: 90px;
	}

	&__text {
		&-m {
			display: inline-block;
			font-family: $f-main;
			font-size: 12px;
			line-height: 21px;
			color: $color-text;

			&--mx {
				margin: $space-0 $space-5;
			}
		}
		&--category {
			width: 100px;
			color: $color-blue;
		}
	}

	&__title {
		display: inline-block;
		font-family: $f-main;
		font-size: 18px;
		color: $color-text;
	}

	&__icon {
		width: 20px;
		margin-right: $space-15;
	}

	&__edit {
		width: 30px;
		padding: $space-5;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__edit-img {
		width: 20px;
		height: 20px;
	}

	&__bill {
		margin: $space-0 $space-5;
	}
}

.course-sort {
	width: 100%;
	display: flex;
	flex-direction: column;

	&__title {
		font-family: $f-main;
		font-size: 16px;
		color: $color-text;
	}

	&__body {
		display: flex;
		align-items: flex-end;
	}

	&-input {
		max-width: 100px;
		padding: $space-0 $space-10 $space-0 $space-0;
		&__input {
			width: 100%;
			padding: $space-5 $space-10;
			border: 1px solid $color-border;
			box-shadow: $shadow;
			border-radius: 5px;
			height: 40px;
			font-size: 18px;
			font-family: $f-main;
			color: $color-text;

			&::placeholder {
				color: $color-border-dark;
				font-weight: 300;
				font-family: $f-main-l;
			}
		}
	}

	&__button {
		max-width: 120px;
		width: 100%;
		margin-right: $space-10;
		position: relative;
		font-family: $f-main-l;
	}

	&__data-time {
		display: flex;
		align-items: center;
		margin: $space-0 $space-10;
	}

	&__data-text {
		font-family: $f-main;
		font-size: 18px;
		color: #111111;

		&:first-of-type {
			font-size: 14px;
			font-family: $f-main-l;
			margin-right: $space-5;
		}
	}

	&__arrow {
		padding: $space-15 $space-0;
	}
}

.save-sort-btn-wrap {
	width: 100%;
	display: flex;

	margin: $space-15;
}
