@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";
@import "../../../styles/settings/mixin.scss";

.category {
	@include container;
	.col-4 {
		padding: $space-10 $space-15;
	}

	&-types {
		padding: $space-15 $space-0;
	}

	&__wrap-image {
		padding: $space-10 $space-0;
		display: flex;
	}
}
