@import '../../../styles/settings/breakpoints.scss';
@import '../../../styles/settings/variable.scss';
@import '../../../styles/settings/mixin.scss';

.login{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: $color-blue;
    &__inner{
        max-width: 540px;
        width: 100%;
        box-shadow: $shadow-dark;
        border-radius: 10px;
        padding: $space-10 $space-15;
        background-color: $color-white;
    }

    &__wrap-logo{
        max-width: 100px;
        width: 100%;
        margin: $space-15 auto $space-0;
    }

    &__error{
        height: 20px;
        font-size: 14px;
        font-family: $f-main;
        color: $color-text;
        display: block;
        text-align: center;
    }

    &__button{
        @include button('success');
        max-width: 220px;
        width: 100%;
        margin: $space-10 auto;
        display: block;
    }

    &-input{
        @include input('text')
    }
}

.social-auth{
    display: flex;

    &__button{
        cursor: pointer;
    }
}