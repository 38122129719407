@import "../settings/variable.scss";

.custom-radio {
	display: flex;
	align-items: center;
	padding: $space-5 $space-0;

	&__checkbox {
		position: relative;
		width: 20px;
		height: 20px;
		border: 1px solid #333;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&::before {
			position: absolute;
			content: "";
			width: 20px;
			height: 20px;
			border-radius: 50px;
			background-color: $color-blue-light;
			border: 1px solid $color-border-dark;
		}

		&--select {
			&::before {
				position: absolute;
				content: "";
				width: 20px;
				height: 20px;
				border-radius: 50px;
				background-color: $color-green;
				border: 1px solid rgb(0, 173, 0);
				animation: 100ms select 50ms ease-out;
			}
		}
	}

	&__label {
		margin-left: 20px;
		cursor: pointer;
	}
}

@keyframes select {
	from {
		width: 10px;
		height: 10px;
	}
	to {
		width: 26px;
		height: 26px;
	}
}

.switcher-startPoint {
	display: flex;
	margin-left: 20px;

	.custom-radio {
		&__checkbox {
			width: 15px;
			height: 15px;

			&::before {
				width: 15px;
				height: 15px;
			}
		}

		&__label {
			margin-left: 5px;
			margin-right: 10px;
			cursor: pointer;
		}
	}
}
