@import "../styles/settings/breakpoints.scss";
@import "../styles/settings/variable.scss";

*,
*::before,
*::after {
	box-sizing: border-box;
}
h1,
h2,
h3,
h4 {
	font-family: $f-averta;
	font-weight: normal;
	color: $color-title;
	margin: 0;
}
h1 {
	font-size: 32px;
}
h2 {
	font-size: 28px;
}
h3 {
	font-size: 24px;
}
h4 {
	font-size: 20px;
}
p,
a {
	font-family: $f-main;
	font-weight: normal;
	color: $color-text;
	font-size: 16px;
}
img {
	width: 100%;
}
button {
	cursor: pointer;

	&:focus {
		outline: none;
	}
}
.container {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
}
.content {
	position: relative;
	width: 100%;
	padding: 15px;
	&__item {
		padding: $space-5 $space-0;
	}
	&__images-block {
		display: flex;
	}
}
input[type="Number"] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
	margin: 0;
}

@import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
.loader-wrap {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.wrapper-edit-button {
	padding: $space-15;
}

.list-category {
	border-bottom: 1px solid $color-border-dark;
	padding-bottom: $space-15;
	&__title {
		display: flex;
		justify-content: space-between;
		font-size: 32px;
		&--span {
			color: $color-blue;
			font-size: 24px;
			margin-left: auto;
			display: inline-block;
		}
	}
}
