@import "../../../styles/settings/variable.scss";

.list-select {
	&__selected-items {
		border-top: 1px solid $color-border;
		padding: $space-10 $space-5;
		background-color: $color-border;
	}

	&__selected-item {
		display: inline-flex;
		align-items: center;
		padding: $space-5;
		background-color: $color-white;
		border-radius: 3px;
		margin: 3px;
	}

	&__close {
		width: 12px;
		height: 12px;
		padding: 1px;
		border: 1px solid $color-border;
		border-radius: 3px;
		margin-left: 3px;
		cursor: pointer;
	}
}
