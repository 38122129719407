@import '../../../styles/settings/variable.scss';
@import '../../../styles/settings/mixin.scss';

.music{
    @include container;

    &__file-reader{
        display: flex;
        align-items: center;
        padding: $space-15 $space-0 $space-5;

        .image-block__button{
            margin: 0;
            margin-right: $space-15;
        }
    }

    &__icon-upload{
        width: 30px;
        height: 30px;
    }

    &__button-wrap{
        padding: $space-15 $space-0;
    }

    &-wrap-list{
        margin-top: $space-15;
    }
}