@import "../settings/variable.scss";
@import "../settings/mixin.scss";

.message {
	&__text {
		font-family: $f-main;
		font-size: 14px;
		font-weight: normal;
		margin: 0;

		&--success {
			color: $color-blue;
		}

		&--error {
			color: $color-red;
		}
	}
}
