.radio-input {
	padding: $space-5;
	&__input {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		cursor: pointer;
	}
	&__label {
		margin-left: 20px;
	}
}
