@import "../../../styles/settings/variable.scss";

.panel {
	min-height: 100vh;
	display: flex;
	&__inner {
		display: flex;
		width: 100%;
	}

	&__left {
		height: 100%;
		display: flex;
		background-color: rgb(132, 165, 255);
		box-shadow: $shadow;
		border-right: 1px solid $color-border-dark;
	}

	&__right {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
	}
}
