@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";

.image {
	position: relative;
	border-radius: $space-5;
	overflow: hidden;
	border: 2px solid #ccc;
	width: 100%;
	background-color: $color-border;
	display: flex;
	align-items: center;
	justify-content: center;

	&__img {
		width: auto;
		height: 100%;
	}
	&__editor {
		position: absolute;
		right: $space-5;
		bottom: $space-5;
		z-index: 2;
		width: 40px;
		height: 40px;
		padding: $space-5;
		background-color: rgba(255, 255, 255, 0.486);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		user-select: none;
		-webkit-user-drag: none;
		cursor: pointer;
	}
}
