@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";
@import "../../../styles/settings/mixin.scss";

.benefits {
	@include container;
    
    &__buttons{
        margin-top: $space-15;
    }
}

.benefits-subtitle {
	font-size: 18px;
	font-family: $f-main-l;
	font-weight: 500;
	letter-spacing: 1px;
	color: $color-text;
	display: flex;
	margin-bottom: 10px;
}

.benefits-textarea {
    margin-top: $space-20;
	.textarea__label {
		font-size: 18px;
		font-family: $f-main-l;
		font-weight: 500;
		letter-spacing: 1px;
		color: $color-text;
		display: flex;
		margin-bottom: 10px;
    }
    
    .textarea__text{
        height: 225px;
        resize: none;
    }
}
