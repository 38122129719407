$width--laptop: 1240px;
$width--laptop-wrap: 1180px;
$width--laptop-m: 1024px;
$width--tablet: 993px;
$width--phone: 900px;
$width--phone-down: 899px;
$width--phone-l: 768px;
$width--phone-l-up: 767px;
$width--phone-l-down: 723px;
$width--phone-mmm: 525px;
$width--phone-mm: 500px;
$width--phone-m: 425px;
$width--phone-sm: 400px;
$width--phone-s: 375px;
$width--phone-min: 320px;

@mixin media--max($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}

@mixin media--min($width) {
    @media screen and (min-width: $width) {
        @content;
    }
}