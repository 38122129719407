@import "../../../styles/settings/variable.scss";
@import "../../../styles/settings/fonts.scss";

.left-sidebar {
	display: flex;
	flex-direction: column;
	width: 220px;

	&--close {
		width: 80px;
	}

	&__header {
		height: 69px;
	}
}

.nav {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - 69px);
}

.menu {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin: 0;

	&__item {
		width: 100%;
		list-style: none;
		line-height: 20px;
		text-transform: uppercase;
		height: 50px;
		display: flex;
		align-items: center;

		&-close {
			position: relative;
			height: 50px;
			display: flex;
			align-items: center;
			&:hover {
				background-color: $color-blue-hover;
			}
		}

		&__icon {
			width: 25px;
			margin-bottom: $space-5;
		}

		&:hover {
			background-color: $color-blue-hover;
		}

		&--active {
			background-color: $color-blue-hover;

			.menu__link::after {
				width: 100%;
			}
		}
	}

	&__tooltip {
		position: absolute;
		width: 200px;
		height: 30px;
		padding: $space-5 $space-10;
		border-radius: 3px;
		background-color: $color-white;
		font-family: $f-main-l;
		font-size: 18px;
		color: $color-title;
		left: 50px;
		box-shadow: $shadow-dark;
		display: none;
		align-items: center;
		transition: 0.3s;

		&:hover {
			cursor: initial;
		}
	}

	&__link {
		position: relative;
		width: 100%;
		font-family: $f-main-l;
		padding: $space-5;
		letter-spacing: 2px;
		font-size: 14px;
		font-weight: bold;
		color: $color-white;
		text-decoration: none;
		display: inline-block;

		&--close {
			padding: $space-5;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&:hover {
			.menu__tooltip {
				position: absolute;
				padding: $space-5 $space-10;
				border-radius: 3px;
				background-color: $color-white;
				font-family: $f-main-l;
				font-size: 14px;
				color: $color-title;
				left: 50px;
				box-shadow: $shadow-dark;
				display: flex;
				z-index: 10;
				transition: 0.3s;
			}
		}
	}

	&__icon {
		max-width: 25px;
		height: auto;
		width: 100%;
		margin-bottom: $space-0;
		margin-right: $space-5;
	}

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: $space-10;
		font-size: 22px;
		font-family: $f-main;
		color: $color-white;
		cursor: pointer;

		img {
			width: 25px;
			margin-right: $space-10;
		}

		&--close {
			img {
				margin-right: $space-0;
			}
		}
	}
}
