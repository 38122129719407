@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";

.home-page {
	width: 100%;
	padding: $space-15;
}

.list-children {
	width: fit-content;
	height: 400px;
	overflow-y: auto;
	border: 1px solid $color-border;
	padding: $space-10;
	padding-right: 10px;
}
.children {
	width: 300px;
	border: 1px solid #c2c2c2;
	padding: 5px;
	border-radius: 8px;
	margin-bottom: 10px;
}

.childrenBody{
	display: flex;
	flex-direction: column;

	&__text{
		font-size: 14px;
		font-family: $f-main-l;
		font-weight: 500;
		letter-spacing: 1px;
		color: $color-text;
		margin: 0;
	}

	&__link{
		font-size: 14px;
		font-family: $f-main;
		font-weight: 500;
		letter-spacing: 1px;
		color: $color-blue;
		margin: 0;
	}
}
