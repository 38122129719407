@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";

.header {
	display: flex;
	width: 100%;
	padding: 5px 15px;
	border-bottom: 1px solid $color-border-dark;
	min-height: 70px;
}

.logo {
	text-align: center;
	text-decoration: none;
	display: flex;
	align-items: center;

	&-header {
		display: flex;
		align-items: center;
	}

	&__img {
		max-width: 35px;
		width: 100%;
		margin-right: 15px;
		margin-top: 10px;
	}

	&__title {
		font-family: $f-averta;
		color: $color-title;
		letter-spacing: 1px;
		margin: 0;
	}
}

.header-account {
	width: 200px;
	position: relative;
	display: flex;
	align-items: center;
	margin-left: auto;

	&__image-wrap {
		width: 40px;
		height: 40px;
		margin-right: $space-15;
	}

	&__image {
		width: 40px;
		height: 40px;
		background-color: $color-border;
		border: 1px solid $color-border-dark;
		display: block;
		border-radius: 50%;
	}
}

.header-account-select {
	width: 150px;
	position: absolute;
	right: 0;
	top: 3px;
	z-index: 1;

	&__select {
		padding: 5px 20px;
		box-shadow: $shadow;
		border-radius: 5px;
		background-color: $color-border-dark;
		z-index: 3;
		color: $color-white;
	}

	&__label {
		margin-bottom: 0;
	}

	&__first-option {
		position: relative;
		display: flex;
		align-items: center;
		padding: 5px 0px;
		cursor: pointer;

		&::before {
			position: absolute;
			content: "";
			display: block;
			width: 10px;
			height: 1px;
			right: 8px;
			background-color: $color-text;
			transform: rotate(45deg);
		}

		&::after {
			position: absolute;
			content: "";
			display: block;
			width: 10px;
			height: 1px;
			right: 0px;
			background-color: $color-text;
			transform: rotate(135deg);
		}
	}
}
