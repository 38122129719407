@import "../settings/breakpoints.scss";
@import "../settings/variable.scss";

.row {
	margin-left: -$space-15;
	margin-right: -$space-15;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@include media--min($width--phone) {
		flex-direction: row;
	}

	&--column {
		flex-direction: column;
	}

	&--column-rewerse {
		flex-direction: column-reverse;
		@include media--min($width--phone) {
			flex-direction: row;
		}
	}
}

.col {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (1 / 12 * 100%);
}
.col-2 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (2 / 12 * 100%);
}
.col-3 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (3 / 12 * 100%);
}
.col-4 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (4 / 12 * 100%);
}
.col-5 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (5 / 12 * 100%);
}
.col-6 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (6 / 12 * 100%);
}
.col-7 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (7 / 12 * 100%);
}
.col-8 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (8 / 12 * 100%);
}
.col-9 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (9 / 12 * 100%);
}
.col-10 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (10 / 12 * 100%);
}
.col-11 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (11 / 12 * 100%);
}
.col-12 {
	padding-left: $space-15;
	padding-right: $space-15;
	width: (12 / 12 * 100%);
}

.col-m-2 {
	@include media--min($width--phone) {
		width: (2 / 12 * 100%);
	}
}
.col-m-3 {
	@include media--min($width--phone) {
		width: (3 / 12 * 100%);
	}
}
.col-m-4 {
	@include media--min($width--phone) {
		width: (4 / 12 * 100%);
	}
}
.col-m-5 {
	@include media--min($width--phone) {
		width: (5 / 12 * 100%);
	}
}
.col-m-6 {
	@include media--min($width--phone) {
		width: (6 / 12 * 100%);
	}
}
.col-m-7 {
	@include media--min($width--phone) {
		width: (7 / 12 * 100%);
	}
}
.col-m-8 {
	@include media--min($width--phone) {
		width: (8 / 12 * 100%);
	}
}
.col-m-9 {
	@include media--min($width--phone) {
		width: (9 / 12 * 100%);
	}
}
.col-m-10 {
	@include media--min($width--phone) {
		width: (10 / 12 * 100%);
	}
}
.col-m-11 {
	@include media--min($width--phone) {
		width: (11 / 12 * 100%);
	}
}
.col-m-12 {
	@include media--min($width--phone) {
		width: (12 / 12 * 100%);
	}
}

.col--m {
	max-width: 500px;
}

.py-5{
	padding: $space-5 $space-0;
}
.py-10{
	padding: $space-10 $space-0;
}
.py-15{
	padding: $space-15 $space-0;
}