@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";

.inner-menu {
	width: 100%;
	display: flex;
	padding: $space-0;

	&__item {
		list-style: none;
		display: flex;
		align-items: center;
		background-color: $color-blue;
		padding: $space-0;
		cursor: pointer;
		margin-right: $space-5;
		border-radius: 5px;

		&:hover {
			background-color: $color-blue-light;
			.inner-menu__link {
				color: $color-text;
			}
		}
	}

	&__link {
		position: relative;
		font-family: $f-main-l;
		font-size: 16px;
		font-weight: 500;
		color: $color-white;
		letter-spacing: 1px;
		text-decoration: none;
		display: flex;
		align-items: center;
		padding: $space-10 $space-15;
	}

	.input-text {
		padding: 0;
		display: flex;
		justify-content: flex-end;

		&__label {
			display: none;
		}

		&__input {
			max-width: 300px;
			margin-left: auto;
			height: 38px;
		}
	}
}
