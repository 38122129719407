@import "../../styles/settings/breakpoints.scss";
@import "../../styles/settings/variable.scss";
@import "../../styles/settings/mixin.scss";
.filereader-block {
	display: flex;
	&__icon {
		max-width: 30px;
		padding: $space-15 $space-0;

		@include media--min($width--laptop) {
			flex-direction: row;
		}

		&--btn {
			padding: $space-5 $space-0;
		}
		&--wrap {
			width: 100%;
			padding: $space-5 $space-0;
		}
	}
	&__button {
		width: 220px;
		margin-right: $space-10;
		margin-top: $space-10;
	}
}
