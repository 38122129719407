@import "./fonts.scss";

// ======= fonts ======
$f-main: futuraMedium;
$f-main-l: futuraLight;
$f-averta: avertademo;

//======== colors =====
$color-text: rgb(17, 17, 17);
$color-title: rgb(16, 0, 37);
$color-white: #ffffff;
$color-border: rgb(207, 207, 207);
$color-border-dark: rgb(121, 121, 121);
$color-blue-light: rgb(206, 219, 255);
$color-blue: rgb(55, 106, 247);
$color-blue-hover: rgb(91, 111, 165);
$color-green: rgb(0, 255, 0);
$hover-grey-light: rgb(245, 245, 245);
$shadow: 0px 0px 3px 3px rgb(243, 243, 243);
$shadow-dark: 1px 3px 2px 1px rgba(37, 37, 37, 0.753);
$shadow-clear: 1px 3px 3px 2px rgba(255, 255, 255, 0);
$color-red: rgb(255, 10, 10);

// ======= space ======

$space-0: 0px;
$space-5: 5px;
$space-10: 10px;
$space-15: 15px;
$space-20: 20px;
$space-25: 25px;
$space-50: 50px;
$space-100: 100px;
$space-150: 150px;

$f-size: 16px;
$f-size-m: 24px;
$f-size-l: 30px;
