@import "../../../styles/settings/mixin.scss";
@import "../../../styles/settings/variable.scss";

.all-courses {
	@include container;
	.list__item--box:nth-child(2) {
		width: 90%;
	}
	.list__item--box:nth-child(3) {
		flex-grow: 0;
	}
}
