@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";
@import "../../../styles/settings/mixin.scss";

.children-profile {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #2c2c2c;
  max-width: 375px;

  &__text {
    color: $color-blue;
    font-size: 16px;
    margin: 0;
  }

  &__span {
    color: #474747;
    font-size: 14px;
    margin: 0;
  }
}

.norm {
  padding: 10px;
  margin: 0 0 10px;
  list-style: none;
  background-color: #e5e5e5;
  border-radius: 8px;

  &__li {
    padding: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  &__text {
    color: $color-blue;
    font-size: 14px;
  }

  &__text_grey{
    color: #3c3c3c;
    font-size: 12px;
  }
}
