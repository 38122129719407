@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";
@import "../../../styles/settings/mixin.scss";

.course {
	@include container;

	.checkBlock__label {
	}
}

.course-control {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-top: 36px;

	@include media--min($width--phone-l) {
		flex-direction: row;
	}
	&__button {
		min-width: 145px;
		margin: $space-5 $space-0;
		@include media--min($width--phone-l) {
			margin-right: 10px;
		}
	}
}

.wrap-range {
	display: flex;
	align-items: center;
}

.range-toggle {
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.range {
	padding: $space-15 $space-0;

	&__box {
		display: flex;
	}

	&__item {
		max-width: 120px;

		&:first-child {
			margin-right: $space-5;
		}
	}

	&-input {
		@include input("text");

		&__input {
			appearance: none !important;
		}
	}

	&__text {
		&--mb-10 {
			margin-bottom: $space-10;
		}
	}
}
