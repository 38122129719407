@import "../../../styles/settings/mixin.scss";
@import "../../../styles/settings/variable.scss";

.search {
	display: flex;
	justify-content: flex-end;
	margin-left: auto;

	&__input {
		@include input("text");

		width: 250px;
		height: 38px;
		padding: $space-0 $space-5;
	}

	&__reset {
		@include button("light");

		margin-left: $space-5;
	}
}
