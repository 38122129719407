@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/mixin.scss";
@import "../../../styles/settings/variable.scss";

.lesson {
	@include container;

	.custom-radio {
		padding: 0;
	}
}
