@import '../settings/variable.scss';

.input-range {
    width: 100%;
    padding: $space-15 $space-0;
    &__label {
        font-size: 18px;
        font-family: $f-main;
        font-weight: 500;
        letter-spacing: 1px;
        color: $color-text;
        display: flex;
        margin-bottom: $space-50;
    }

    &__input {
        padding: $space-15 $space-0;
        display: flex;
        align-items: center;
    }

    &__track {
        background-color: $color-border;
        height: 5px;
    }

    &__thumb {
        background-color: $color-blue;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $f-averta;
        font-size: 18px;
        color: $color-white;
        border: .5px solid #000;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}