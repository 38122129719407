@import "../../../styles/settings/variable.scss";

.list {
	padding-left: $space-0;
	height: 65vh;
	overflow-y: auto;

	&__item {
		list-style: none;
		border-bottom: 1px solid $color-blue-light;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $space-0 $space-10;

		&--box {
			padding: $space-10 $space-0;
			display: flex;
			align-items: center;
		}

		&--box-title {
			flex-grow: 1;
		}

		&--box-icon {
			width: 5%;
			padding: $space-10 $space-10 $space-10 $space-0;
		}

		&--box-img {
			max-width: 100px;
			overflow: hidden;
			padding: $space-5;
		}

		&:hover {
			background-color: $color-blue-light;
		}
	}

	&__image {
		width: 100%;
		margin-right: $space-10;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	&__title {
		font-size: $f-size-m;
		font-family: $f-main;
		color: $color-text;
		margin: 0;
	}

	&-editor__button {
		margin-top: auto;
		background-color: inherit;
		border: none;
	}

	&__type {
		font-size: $f-size;
		color: $color-text;
		font-family: $f-main-l;
		display: flex;
		margin-top: auto;

		&--title {
			display: block;
			margin-right: $space-5;
		}
	}
}
