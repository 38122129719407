@import "../../../../styles/settings/variable.scss";
@import "../../../../styles/settings/mixin.scss";
.arrow-fetch {
	display: flex;
	align-items: center;

	&__text {
		font-size: 24px;
		color: $color-text;
		font-family: $f-main-l;
		margin: $space-5 $space-15 $space-0;
	}

	&__btn {
		width: 50px;
		height: 30px;
		display: flex;
		align-items: center;
		border: 1px solid $color-border;
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;

		&:hover {
			background-color: $color-border;
			transition: 0.3s;
		}
	}

	&__img {
		width: 30%;
		display: inline-block;
		margin: auto;
	}
}
