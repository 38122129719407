@import "../settings/variable.scss";

.checkBlock {
	display: flex;
	align-items: center;
	padding: $space-5 $space-0;

	&__label {
		font-size: 18px;
		font-family: $f-main-l;
		font-weight: 500;
		letter-spacing: 1px;
		color: $color-text;
		display: flex;
	}

	&__checkbox {
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 3px;
		border: 1px solid $color-text;
		cursor: pointer;
		margin-right: $space-10;

		&--checked {
			background-color: $color-green;
		}

		&--unchecked {
			background-color: $color-border;
		}
	}
}
