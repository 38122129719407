.all-experts {
	.list__item {
		padding: 10px;
	}
	.list__item--box-icon {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		overflow: hidden;
		padding: 0;
		margin-right: 10px;
	}
	.list__image {
		border: none;
		margin: 0;
		object-fit: cover;
		object-position: top;
	}
}
